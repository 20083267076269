export const PROD_API_URL = "https://data-api.ems-inc.ca";
export const LOCAL_API_URL = "http://localhost:8080";
export const DEFAULT_API_URL = PROD_API_URL;

export const MANUFACTURING_SITE_NAME = "Manufacturing";
export const GET_BOARD_DATA_URL = "/lab-data/getBoardMapping/:board";
export const CHANGE_SAMPLING_TIME = "/lab-data/changeSampleTime";
export const CHANGE_BOARD_STATE = "/lab-data/setBoardState";
export const CHANGE_BOARD_IR = "/lab-data/setBoardMapping";
export const CHANGE_BOARD_SITE = "/lab-data/setBoardSite";
export const GET_BLUE_BOARDS_DATA = "/lab-data/getBlueBoardData/:board";
export const CHANGE_SITE_QUANTIFIER_DATA = "/lab-data/siteQuantifierData/";
export const KPI_GET_API = "/sensor-boards/search/:id";
export const RG_TEST_GET_API = "/sensor-boards/search-test/:id/:key";
export const CALIBRATION_INSERT_O2 = "/sensor-boards/calibration/oxygen/add";
export const GET_NEW_BOARDS_BATCH_CALIBRATION = "/calibration/getBatch/:type/:batch";
export const GET_BOARD_DETAILS_CALIBRATION = "/calibration/check-teraterm-values/:bb_label/:batch";
export const GET_O2_CALIBRATION = "/calibration/gas";
export const GET_CALIBRATION_BATCH = "/calibration/batchGas";
export const GET_LATEST_CALIBRATION = "/lab-data/get-latest-calibration-data/:bb_label";
export const GET_BATCH_SENSOR_TYPE = "/calibration/getBatchSensorType/:batch";

export const ADD_NDIR_CALIBRATION = "/calibration/add/ndir";
export const ADD_KPI = "/sensor-boards/kpi/add";
export const UPDATE_SITE_GATEWAY = "/lab-data/update-gateway";
export const UPDATE_SITE_GATEWAY_NOTES = "/lab-data/update-gateway-notes";
export const SEARCH_GATEWAYS = "/lab-data/search-gateway";
export const SEND_ALL_CALIBRATIONS = "/calibration/add/allCalibrations";
export const SEND_ALL_BOARD_CALIBRATIONS = "/calibration/add/allBoardCalibrations";
export const UPDATE_NORM_RESPONSES = "/calibration/add/normResponse";

export const GET_SENSOR_HISTORY = "/sensor-boards/history";
export const UPDATE_SENSOR_HISTORY = "/sensor-boards/update-history";

export const SEND_SAVINGS_DATA = "/savings-calculator/add";
export const BELL = {
   DEFAULT: "https://restapi7.jasper.com/rws/api/v1/devices",
   USAGE: "/:ICCID/ctdUsages",
   SESSION_INFO: "/:ICCID/sessionInfo",
   EDIT_DETAILS: "/:ICCID",
};

export const PAGES = {
   EXECUTIVE: "/executive",
   QUANTIFIER: "/quantifier",
   DISTRIBUTOR: "/distributor",
   TESTING_BOARDS: "/board_testing",
   BLUE_BOARDS: "/blue_boards",
   CALIBRATION: "/calibration",
   AG_BOARDS: "/ag_testing",
   GATEWAYS: "/gateways",
   SAVINGS_CALCULATOR: "/savings-calculator",
   CARBON: "/carbon",
   PLANS_OPTIMIZATION: "/plans-optimization",
};

export const PAGES_TITLES = {
   "/executive": "Executive",
   "/quantifier": "Quantifier",
   "/distributor": "Distributor",
   "/board_testing": "Board Testing",
   "/blue_boards": "Board Details",
   "/calibration": "Calibration",
   "/gateways": "Gateways",
   "/savings-calculator": "Savings Calculator",
   "/carbon": "Carbon",
   "/plans-optimization" : "Plans Optimization"
};

export const LINKS = {
   CONTACT_US: "https://ems-inc.ca/contact-us",
};
export const GAS_BOUNDS = {
   ch4: [-1, 51],
   co2: [-1, 11],
   n2o: [-0.0001, 0.011],
   c3h8: [-0.5, 2.5],
};

export const STATE = {
   CALIBRATION: {
      value: "CALIBRATION",
      name: "Calibration",
   },
   PRE_PRODUCTION_RG: {
      value: "PRE_PRODUCTION_RG",
      name: "Pre Production RG",
   },
   POST_PRODUCTION_RG: {
      value: "POST_PRODUCTION_RG",
      name: "Post Production RG",
   },
   FIELD_RG: {
      value: "FIELD_RG",
      name: "Field RG",
   },
   IN_FIELD: {
      value: "IN_FIELD",
      name: "In Field",
   },
   GARBAGE: {
      value: "GARBAGE",
      name: "Garbage",
   },
};
export const KPI_GET_API_TEST = "/sensor-boards/kpi/get/:test";

export const SITES_ALL_API_URL = "/sites/all/not-internal";

export const KEY_MAPPING_SAVINGS = {
   monitoring: "Monitoring",
   monitoring_remediation: "Monitoring + Remediation",
   less_than_500: "Less than 500 m²(5,400 ft²)",
   between_500_3800: "Between 500 - 3,800 m²(5,400 ft² - 41,000 ft²)",
   between_3800_10000: "Between 3,800 - 10,000 m²(41,400 ft² - 107,000 ft²)",
   ppm_500: "500 ppm F1",
   ppm_1000: "1000 ppm F1",
   ppm_2000: "2000 ppm F1",
   less_than_1: "Less than 1 ppm Benzene",
   between_1_5_ppm: "Between 1 and 5 ppm Benzene",
   between_5_10_ppm: "Between 5 and 10 ppm Benzene",
   unknown: "Unknown (5 ppm Benzene concentration assumed)",
   between_onehalf_5: "Between 1.5m and 5m",
   between_5_10: "Between 5m and 10m",
   between_10_15: "Between 10m and 15m",
   unknown_gw_depth: "Unknown (3m depth to groundwater assumed)",
   unknown_sa: "Unknown (3,800 m² or 41,000 ft² area assumed)",
   unknown_sc: "Unknown soil hydrocarbon concentration (1000 ppm F1 assumed)",
   unknown_gc: "Unknown (5 ppm benzene concentration assumed)",
   LNAPL: "LNAPL (25 ppm benzene concentration assumed)",
};

export const CALIBRATION = "CALIBRATION";
export const RED_GREEN = "RG";

export const CATEGORY_FIELDS = [
   "Device Name",
   "DevEUI",
   "Device Type",
   "Device Type Name",
   "Gateway",
   "Quantifier Name",
   "Site Name",
   "State",
   "Status",
   "board_temperature",
   "concentration_ch4",
   "concentration_co2",
   "concentration_o2",
   "concentration_phc",
   "detector_temperature",
   "humidity",
   "pressure",
   "test_passed",
];
