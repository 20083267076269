import { Spinner } from 'react-bootstrap';
import { PAGES } from './components/constants';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useState, useEffect, Suspense, lazy } from 'react';

import './styles/App.css';
import 'react-clock/dist/Clock.css';
import 'react-calendar/dist/Calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/SavingsCalculator/styling.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import SignIn from './components/Auth/SignIn';

import { MsalProvider } from '@azure/msal-react';

const Main = lazy(() => import('./components/Main'));
const SavingCalculator = lazy(() =>
  import('./components/SavingsCalculator/index')
);

function App({ msalInstance }) {
  const [token, setToken] = useState('');
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [loginActive, setLoginActive] = useState(true);
  const [siteAccess, setSiteAccess] = useState(
    localStorage.getItem('siteAccess')
  );
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem('authenticated')
  );

  useEffect(() => {
    if (token) {
      if (localStorage.getItem('apiToken') !== token) {
        localStorage.setItem('apiToken', token);
        localStorage.setItem('tokenTime', new Date().getTime());
      }
    }
  }, [token]);

  useEffect(() => {
    if (!localStorage.getItem('tokenTime')) {
      setLoginActive(true);
    } else {
      if (!localStorage.getItem('apiToken')) {
        setLoginActive(true);
      } else {
        if (
          Math.ceil(
            Math.abs(localStorage.getItem('tokenTime') - new Date().getTime()) /
              1000 /
              60 /
              60
          ) > 115
        ) {
          setLoginActive(true);
        } else {
          setLoginActive(false);
          setToken(localStorage.getItem('apiToken'));
        }
      }
    }
  }, []);

  const MainPage = ({ targetPage }) => {
    return (
      <Main
        token={token}
        page={targetPage}
        siteAccess={siteAccess}
        // refreshTime={refreshTime}
        authenticated={authenticated}
        autoRefresh={autoRefresh}
        setAutoRefresh={setAutoRefresh}
      />
    );
  };

  const LoginForm = () => {
    return (
      <SignIn
        active={loginActive}
        setActive={setLoginActive}
        authenticated={authenticated}
        setAuthenticated={setAuthenticated}
        setToken={setToken}
        setSiteAccess={setSiteAccess}
      />
    );
  };

  const routePaths = [
    PAGES.QUANTIFIER,
    PAGES.DISTRIBUTOR,
    PAGES.TESTING_BOARDS,
    PAGES.BLUE_BOARDS,
    PAGES.CALIBRATION,
    PAGES.EXECUTIVE,
    PAGES.GATEWAYS,
    PAGES.SAVINGS_CALCULATOR,
    PAGES.CARBON,
    PAGES.PLANS_OPTIMIZATION,
  ];

  return (
    <MsalProvider instance={msalInstance}>
      <div className='App'>
        <Suspense
          fallback={
            <div className='d-flex align-items-center justify-content-center mt-5 mb-5'>
              <Spinner
                variant='primary'
                animation='border'
                role='status'
                style={{ width: '3rem', height: '3rem' }}
              >
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            </div>
          }
        >
          <Routes>
            <Route
              key='/'
              exact
              path='/'
              element={
                token ? (
                  <Navigate to={PAGES.TESTING_BOARDS} />
                ) : (
                  <LoginForm />
                )
              }
            />
            <Route
              exact
              path={PAGES.SAVINGS_CALCULATOR}
              element={<SavingCalculator page={PAGES.SAVINGS_CALCULATOR} />}
            />
            {routePaths.map((path) => (
              <Route  
                key={path}
                path={path}
                element={
                  token ? (
                    <MainPage targetPage={path} />
                  ) : (
                    <LoginForm />
                  )
                }
              />
            ))}
          </Routes>
        </Suspense>
      </div>
    </MsalProvider>
  );
}

export default App;
