import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material';
import { Microsoft } from 'react-bootstrap-icons';
import { DEFAULT_API_URL, PAGES } from '../constants';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';

/**
 * Material UI Sign in template
 * @see https://github.com/mui/material-ui/blob/v6.0.2/docs/data/material/getting-started/templates/sign-in/SignIn.js
 */

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: 20,
  backgroundImage:
    'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
  backgroundRepeat: 'no-repeat',
  ...theme.applyStyles('dark', {
    backgroundImage:
      'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
  }),
}));

export default function SignIn(props) {
  const navigate = useNavigate();
  const [userNameError, setUserNameError] = useState(false);
  const [userNameErrorMessage, setUserNameErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [failedAttempt, setFailedAttempt] = useState(false);
  const [failedAttemptMessage, setFailedAttemptMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [userName, setUserName] = useState('');

  const userNameRef = useRef(null);
  const passwordRef = useRef(null);

  const { instance, accounts } = useMsal();

  const handleMicrosoftSignIn = async () => {
    if (!accounts || accounts.length === 0) {
      await instance
        .loginRedirect({ ...loginRequest, prompt: 'consent' })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    const acquireTokenSilent = async (account) => {
      return instance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
    };

    const attemptAzureLogin = async (azureToken) => {
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS',
        'x-azure-token': azureToken,
      };

      await fetch(`${DEFAULT_API_URL}/login/`, {
        method: 'POST',
        headers: headers,
      })
        .then((response) => response.json())
        .then((json) => checkJSONResponse(json));
    };

    if (accounts?.length > 0) {
      const tokenPromise = acquireTokenSilent(accounts[0]);
      Promise.all([tokenPromise]).then((response) => {
        const azureToken = accounts[0].idToken;
        attemptAzureLogin(azureToken);
      });
    }
    // eslint-disable-next-line
  }, [accounts]);

  useEffect(() => {
    const savedUserName = localStorage.getItem('username');
    if (savedUserName) {
      setUserName(savedUserName);
      setRememberMe(true);
    }
  }, []);

  const attemptLogin = async (username, password) => {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS',
    };

    await fetch(`${DEFAULT_API_URL}/login/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ username: username, password: password }),
    })
      .then((response) => response.json())
      .then((json) => checkJSONResponse(json));
  };

  const handleSubmit = () => {
    const userName = userNameRef.current.value;
    const password = passwordRef.current.value;

    if (rememberMe) {
      localStorage.setItem('username', userName);
    } else {
      localStorage.removeItem('username');
    }

    attemptLogin(userName, password);
  };

  const checkJSONResponse = (json) => {
    if (json.message === 'Success') {
      setFailedAttempt(false);
      props.setAuthenticated(true);
      props.setActive(false);
      props.setToken(json.token);
      props.setSiteAccess(json.sites);
      localStorage.setItem('siteAccess', json.sites);
      localStorage.setItem('authenticated', true);
      localStorage.setItem('apiToken', json.token);
      localStorage.setItem('tokenTime', new Date().getTime());
      navigate(`${PAGES.TESTING_BOARDS}`);
    } else {
      setFailedAttempt(true);
      setFailedAttemptMessage(json.message);
    }
  };

  const validateInputs = () => {
    const userName = document.getElementById('userName');
    const password = document.getElementById('password');

    let isValid = true;

    if (!userName.value || userName.value.trim().length < 1) {
      setUserNameError(true);
      setUserNameErrorMessage('Please enter a valid username.');
      isValid = false;
    } else {
      setUserNameError(false);
      setUserNameErrorMessage('');
    }

    if (!password.value || password.value.trim().length < 1) {
      setPasswordError(true);
      setPasswordErrorMessage('Please enter a valid password.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }
    return isValid;
  };

  return (
    <>
      <SignInContainer direction='column' justifyContent='space-between'>
        <Card
          variant='outlined'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 10,
          }}
        >
          <img
            src='/ems-logo-only-01.png'
            alt='EMS Sign In'
            style={{
              width: '70px',
              height: '70px',
              objectFit: 'cover',
              borderRadius: '4px 4px 0 0',
            }}
          />
          <Typography
            component='h1'
            variant='h1'
            sx={{ width: '100%', fontSize: 30 }}
          >
            Sign in
          </Typography>
          <Box
            component='form'
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <FormControl>
              <TextField
                inputRef={userNameRef}
                error={userNameError}
                helperText={userNameErrorMessage}
                id='userName'
                type='text'
                name='userName'
                placeholder='Username'
                autoComplete='userName'
                autoFocus
                required
                fullWidth
                variant='outlined'
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                color={userNameError ? 'error' : 'primary'}
                sx={{ ariaLabel: 'userName' }}
              />
            </FormControl>
            <FormControl>
              <TextField
                inputRef={passwordRef}
                error={passwordError}
                helperText={passwordErrorMessage}
                name='password'
                placeholder='Password'
                type='password'
                id='password'
                autoComplete='current-password'
                autoFocus
                required
                fullWidth
                variant='outlined'
                color={passwordError ? 'error' : 'primary'}
              />
            </FormControl>
            {failedAttempt && (
              <Typography variant='body2' color='error' sx={{ mt: 1 }}>
                {failedAttemptMessage}
              </Typography>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  value='remember'
                  color='primary'
                />
              }
              label='Remember me'
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              fullWidth
              variant='contained'
              onClick={() => validateInputs() && handleSubmit()}
            >
              Sign in
            </Button>
            <Button
              fullWidth
              variant='outlined'
              onClick={async () => {
                await handleMicrosoftSignIn();
              }}
              startIcon={<Microsoft />}
            >
              Sign in with Microsoft
            </Button>
          </Box>
        </Card>
      </SignInContainer>
    </>
  );
}
