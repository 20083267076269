import "./index.css";
import App from "./App";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { msalConfig } from './components/Auth/authConfig';
import { EventType, PublicClientApplication } from '@azure/msal-browser';

const msalInstance = new PublicClientApplication(msalConfig);
const root = createRoot(document.getElementById("root"));


// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
   // Account selection logic is app dependent. Adjust as needed for different use cases.
   msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
       const account = event.payload.account;
       msalInstance.setActiveAccount(account);
   }
});

root.render(
   <BrowserRouter>
       <App msalInstance={msalInstance}/>
   </BrowserRouter>
);

